<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Sensores - Detalle del Tanque / Bebederos</h4>
            <div class="small text-muted">Visulización de los niveles del tanque y bebederos en tiempo real</div>
          </b-col>          
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="2" class="d-none d-lg-block" v-if="data.info.tank && data.info.type_asignation == 'tanque'">
          
          <b-card no-body border-variant="light"> 
            <div class="text-center">          
              <b-card-img :src="data.info.tank.image" v-if="data.info.tank.image" bottom class="mt-3 detail-sensor-tank-img"></b-card-img>              
              <b-icon v-else icon="image" class="h1 mt-3" v-b-tooltip.hover title="Imagen No Disponible" />     
            </div>

            <b-card-body>                              
              <b-card-title>{{data.info.tank.name}}</b-card-title>
              <b-card-sub-title class="mb-3">{{data.info.tank.code}}</b-card-sub-title>   

              <b-card-text>
                {{data.info.tank.description}} <br><br>
                <b>Diametro:</b> {{data.info.tank.diameter}} cm<br>
                <b>Alto:</b> {{data.info.tank.height}} cm<br>
                <b>Capacidad:</b> {{data.info.tank.capacity}} lt<br>
              </b-card-text>
            </b-card-body>

            <a :href="maps.urlMaps" target="_blank" v-if="maps.urlMaps">
              <div class="sensor-customer-detail-custom-mapa">                
                <l-map          
                  :options="maps.options"
                  :zoom="maps.zoom"
                  :center="maps.center"                    
                >
                  <l-tile-layer :url="maps.url"></l-tile-layer>
                    <l-marker :lat-lng="maps.markerLatLng" />                                  
                </l-map>                            
              </div> 
            </a>  
            <b-list-group flush>
              <b-list-group-item><b>Contenido: </b> {{data.info.type}}</b-list-group-item>
              <b-list-group-item><b>Serie: </b> {{data.info.serial_number}}</b-list-group-item>
              <b-list-group-item><b>Vto: </b> {{moment(data.info.expiration).format('DD/MM/YYYY')}}</b-list-group-item>
            </b-list-group>           
          </b-card>      

        </b-col>

        <b-col lg="2" class="d-none d-lg-block" v-if="data.info.type_asignation == 'bebedero'">            
          
          <b-card no-body border-variant="light"> 
            <b-card-body>                
              <b-card-title>{{data.info.alias_trough}}</b-card-title>
            </b-card-body>

            <a :href="maps.urlMaps" target="_blank" v-if="maps.urlMaps">
              <div class="sensor-customer-detail-custom-mapa">                
                <l-map          
                  :options="maps.options"
                  :zoom="maps.zoom"
                  :center="maps.center"                    
                >
                  <l-tile-layer :url="maps.url"></l-tile-layer>
                    <l-marker :lat-lng="maps.markerLatLng" />                                  
                </l-map>                            
              </div> 
            </a>                    
          </b-card>      

        </b-col>

        <b-col lg="10" md="12" sm="12" xs="12">
          
          <b-card header-tag="header" footer-tag="footer" v-if="data.info.type_asignation == 'tanque'">
            <b-row>  
              <b-col md="8" sm="12" xs="12" class="pl-4">
                <h4>Nivel del tanque en tiempo real. <b>{{this.data.info.alias}}</b></h4>
                <b-row>
                  <b-col md="4" sm="12" class="mb-1">
                    <div class="sensors-detail-title-data"> 
                      <i>Nodo:</i>&nbsp;<b-badge variant="dark">{{data.info.sensor_code}}</b-badge>  | 
                      <i>Sensor:</i>&nbsp;<b-badge variant="dark">{{data.info.sensor_val}}</b-badge>
                    </div>
                  </b-col>
                  
                  <b-col lg="8" sm="12" class="mb-1" v-if="litersStatus>=0">
                    <span class="sensors-detail-data-main">      
                      <span v-if="!isCylinder">                                                         
                        <i class="sensors-detail-summary-data-title">Litros:</i>&nbsp;
                        <span v-if="this.litersStatus" :class="{ 'sensors-detail-summary-data-value-darkgreen': alertMin==0, 'sensors-detail-summary-data-value-darkorange': alertMin==1, 'sensors-detail-summary-data-value-darkred': alertMin==2 }">                 
                          {{this.litersStatus}} lt
                        </span>
                        <span v-else>
                          <b-badge variant="secondary">Sin Registro</b-badge>
                        </span>
                      </span>
                      <span v-else>
                        <i class="sensors-detail-summary-data-title">Litros:</i>&nbsp;
                        <span v-if="this.litersStatusCylinder" :class="{ 'sensors-detail-summary-data-value-darkgreen': alertMin==0, 'sensors-detail-summary-data-value-darkorange': alertMin==1, 'sensors-detail-summary-data-value-darkred': alertMin==2 }">                 
                          {{this.litersStatusCylinder}} lt
                        </span>
                        <span v-else>
                          <b-badge variant="secondary">Sin Registro</b-badge>
                        </span>                          
                      </span>

                      &nbsp; | &nbsp;
                      <i class="sensors-detail-summary-data-title">Estado:</i>&nbsp;
                      <span v-if="this.cantidadStatus" :class="{ 'sensors-detail-summary-data-value-darkgreen': alertMin==0, 'sensors-detail-summary-data-value-darkorange': alertMin==1, 'sensors-detail-summary-data-value-darkred': alertMin==2 }">                    
                        {{this.cantidadStatus}}%
                      </span>
                      <span v-else>
                        <b-badge variant="secondary">Sin Registro</b-badge>
                      </span>
                    </span>                                      
                  </b-col>

                  <!--
                  <b-col offset-lg="4" lg="8" sm="12" class="mt-2" v-if="isCone">
                    <span class="sensors-detail-data-main">                                                              
                      <i class="sensors-detail-summary-data-title">Litros cono:</i>&nbsp;
                      <span v-if="this.litersStatusCone" :class="{ 'sensors-detail-summary-data-value-darkgreen': alertMin==0, 'sensors-detail-summary-data-value-darkorange': alertMin==1, 'sensors-detail-summary-data-value-darkred': alertMin==2 }">                    
                        {{this.litersStatusCone}} lt
                      </span>
                      <span v-else>
                        <b-badge variant="secondary">Sin Registro</b-badge>
                      </span>
                    </span>                                      
                  </b-col>                  
                  -->
                </b-row>             
              </b-col>          

              <b-col md="4" sm="12" xs="12" class="mt-3">
                <b-row>                   
                  <b-col md="9" sm="12" class="pr-0 mb-1">
                    <b-row>
                      <b-col md="6" sm="6" class="p-md-0 pr-md-1 p-xs-1 pr-xs-0">
                        <b-form-input type="date" size="sm" v-model="filter.date_start" placeholder="Fecha Desde"></b-form-input>
                        <b-form-input type="time" size="sm" v-model="filter.time_start" v-if="filter.show.hours"></b-form-input>
                      </b-col>
                      <b-col md="6" sm="6" class="p-md-0 pr-md-1 p-xs-1 pr-xs-0">
                        <b-form-input type="date" size="sm" v-model="filter.date_end" placeholder="Fecha Hasta"></b-form-input>
                        <b-form-input type="time" size="sm" v-model="filter.time_end" v-if="filter.show.hours"></b-form-input>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="3">
                    <b-button @click="loadChart()" size="sm" class="w-100" variant="dark">Filtrar</b-button>
                    <b-form-checkbox v-model="filter.show.hours" 
                                      switch 
                                      size="sm" 
                                      class="mt-2 pull-right"
                                      v-if="showHours">
                      Hora
                    </b-form-checkbox>                     
                  </b-col>
                </b-row>
              </b-col>

            </b-row>

            <b-row class="mt-5">
              <b-col md="12">
                <line-chart
                  v-if="chart.loaded"
                  :chartData="chart.chartdata"
                />            
                <b-alert v-else show variant="info">
                  <h4 class="alert-heading">Sin Registros</h4>
                  <p>
                    No se encontraron datos desde el día {{moment(this.filter.date_start).format('DD/MM/YYYY')}} hasta {{moment(this.filter.date_end).format('DD/MM/YYYY')}}                    
                  </p>
                </b-alert>
              </b-col>
            </b-row>
          </b-card>

          <b-card header-tag="header" footer-tag="footer" v-if="data.info.type_asignation == 'bebedero'">
            <b-row> 
              <b-col md="8" sm="12" xs="12" class="pl-4">                
                <h4>Estado del bebedero en tiempo real. <b>{{this.data.info.alias_trough}}</b></h4>
                <b-row>
                  <b-col lg="4" sm="12" class="mb-1">
                    <div class="sensors-detail-title-data"> 
                      <i>Nodo:</i>&nbsp;<b-badge variant="dark">{{data.info.sensor_code}}</b-badge>                       
                    </div>
                  </b-col>                
                </b-row>
              </b-col>
              <b-col md="4" sm="12" xs="12" class="pl-4">                
                <span class="pull-right">
                  
                  <div v-if="statusTrough==1" class="list-sensors-customers-trough-open">
                    Abierto
                  </div>
                  <div v-if="statusTrough==0" class="list-sensors-customers-trough-close">
                    Cerrado
                  </div>                    
                  <div v-if="statusTrough==-1">
                    <i>No Implementado</i>
                  </div>                                                      
                  
                </span>
              </b-col>
            </b-row>
          </b-card>

          <b-row>           
            <b-col md="8">
              <b-card header-tag="header" 
                      footer-tag="footer" 
                      no-body                      
                      :header="'Últimas alertas disparadas desde el día ' + moment(this.filter.date_start).format('DD/MM/YYYY') + ' hasta ' + moment(this.filter.date_end).format('DD/MM/YYYY')"
                      header-bg-variant="dark"
                      header-text-variant="white">

                      <b-table class="mb-0"
                        responsive
                        head-variant="light"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="table.items"
                        :fields="table.fields"  
                        :current-page="table.currentPage"
                        :per-page="table.perPage"                                              
                        v-if="table.items.length">

                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template>  

                        <template v-slot:cell(date_time)="data">
                          {{moment(data.item.date_time).format('DD MMMM - HH:mm')}}
                        </template>
                        <template v-slot:cell(notifications_type_id)="data">
                          <b-badge variant="secondary">{{data.item.type.name}}</b-badge>
                        </template>                        
                        <template v-slot:cell(description)="data">
                          <div v-if="data.item.description.length>70" :title="data.item.description">
                            {{data.item.description.substring(0,70) + '...'}}
                          </div>
                          <div v-else>
                            {{data.item.description}}
                          </div>
                        </template>                                                                     
                        <template v-slot:cell(status)="data">
                          <b-badge v-if="data.item.status" variant="success">Entregado</b-badge>
                          <b-badge v-else variant="danger">Error</b-badge>                          
                        </template>    
                        <template v-slot:cell(error)="data">
                          <b-icon icon="question-circle-fill" class="h3" v-if="data.item.error" v-b-popover.hover.top="data.item.error"></b-icon>                          
                        </template>   

                      </b-table>
                      <b-alert v-else variant="warning" show class="m-3">                        
                        No se disparó ninguna alerta desde el día {{moment(this.filter.date_start).format('DD/MM/YYYY')}} hasta {{moment(this.filter.date_end).format('DD/MM/YYYY')}}
                      </b-alert>

                      <div class="mt-3 mr-3">                      
                        <b-pagination size="sm"
                                      pills
                                      align="right"
                                      :total-rows="getRowCount(table.items)"
                                      :per-page="table.perPage"
                                      v-model="table.currentPage"
                                      v-if="table.items.length"/>                      
                      </div>
              </b-card>
            </b-col>

            <b-col md="4">  
              <b-row>

                <b-col lg="6">     
                  <b-card header-tag="header" 
                          footer-tag="footer" 
                          no-body
                          header="Temperatura"
                          header-bg-variant="dark"
                          header-text-variant="white">

                      <span class="sensors-detail-data-temperature-alert-value text-center">                                                                
                        <span v-if="data.info">
                          {{this.degreesTemperature}}º C
                        </span>
                        <span v-else>
                          0.00º C
                        </span>                         
                        <hr class="sensors-detail-data-temperature-alert-hr">
                        <span class="sensors-detail-data-temperature-alert">
                          Grados Centígrados
                        </span>
                      </span>                                          
                  </b-card>
                </b-col> 

                <b-col lg="6">     
                  <b-card header-tag="header" 
                          footer-tag="footer" 
                          no-body
                          header="Estado de la batería"
                          header-bg-variant="dark"
                          header-text-variant="white">

                    <span class="sensors-detail-data-main text-center">                                          
                      <span v-if="batteryStatus" v-bind:class="{ 'sensor-detail-batery-top': bateryAlert, 'sensor-detail-batery-min': !bateryAlert}">                    
                        <span v-if="batteryStatus">
                          {{this.batteryStatus.toFixed(2)}}
                        </span>
                        <span v-else>
                          0.00
                        </span> 
                        Volt
                        <hr class="sensors-detail-data-main-batery-alert-hr" v-if="this.data.info.batery_alert">
                        <span class="sensors-detail-data-main-batery-alert" v-if="this.data.info.batery_alert">
                          Cambio de batería en {{this.data.info.batery_alert.toFixed(2)}} Volt
                        </span>
                      </span>
                      <span v-else>
                        <b-badge variant="secondary">Sin Registro</b-badge>
                      </span>
                    </span>   
                  </b-card>
                </b-col> 

                <b-col lg="12">     
                  <b-card no-body>
                    <b-alert show v-if="data.info.notifications_active" variant="success" class="detail-sensor-notifications-active">
                      Notificaciones Activas
                      <b-button variant="outline-danger" title="Inactivar" size="sm" class="pull-right detail-sensor-button-inactive" @click="inactiveNotifications()">                        
                        <b-icon icon="toggle-off"></b-icon>
                      </b-button>                      
                    </b-alert>
                    <b-alert show v-else variant="danger" class="detail-sensor-notifications-inactive">
                      Notificaciones Inactivas
                      <b-button variant="outline-success" title="Activar" size="sm" class="pull-right detail-sensor-button-active" @click="activeNotifications()">                        
                        <b-icon icon="toggle-on"></b-icon>
                      </b-button>                       
                    </b-alert>                    
                  </b-card>
                </b-col> 

                <b-col lg="12">
                  <b-list-group>
                    <b-list-group-item href="#" class="flex-column align-items-start mb-3" @click="irNotifications()">
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">Notificaciones</h5>
                        <small>
                          <b-icon icon="chevron-double-right" class="h5"></b-icon>
                        </small>
                      </div>

                      <p class="mb-1">
                        Configurar quien y como reciben las notificaciones
                      </p>
                    </b-list-group-item>
                  </b-list-group>                      
                </b-col>
              </b-row>
            </b-col>                
          </b-row>

          <b-row>
            <b-col>
              <b-card header-tag="header" footer-tag="footer">
                <b-row>
                  <b-col>
                    <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                      <i class="fa fa-angle-double-left"></i>
                      Volver
                    </b-button>
                  </b-col>          
                </b-row>        
              </b-card>
            </b-col>
          </b-row>
        </b-col>          
      </b-row>
      
    </b-col>
  </b-row>  
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import moment from 'moment' 
  import LineChart from './chart/line'    
  
  import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
  import { Icon } from 'leaflet'
  import 'leaflet/dist/leaflet.css';  

  delete Icon.Default.prototype._getIconUrl

  Icon.Default.mergeOptions({    
    iconUrl: Session.getSession().settings.favicon,        
    shadowUrl: Session.getSession().settings.favicon,
    iconSize: [25, 25 * 1.15],
    iconAnchor: [25 / 2, 25 * 1.15],    
    shadowSize: [25, 25 * 1.15],
    shadowAnchor: [25 / 2, 25 * 1.15],        
  })

  export default {
    components: { 
      LineChart,      
      LMap,
      LTileLayer,
      LMarker,   
    },
    data: () => {
      return {      
        customerID: 0,
        access: {
          module_id: Modules.SENSORES,
          profile_id: Profiles.CLIENTE,
          view_reference: 'detailSensorsTank',
          elements: {}
        }, 
        data: {
          id: 0,
          info: {},          
        },      
        table: {
          items: [],
          fields: [            
            {key: 'date_time', label: 'Hora', class:'align-middle', width:"125px"},
            {key: 'notifications_type_id', label: 'Alerta', class:'align-middle', width:"75px"},
            {key: 'to', label: 'Entregado a', class:'align-middle', width:"200px"},            
            {key: 'description', label: 'Descripción', class:'align-middle', width:"225px"},            
            {key: 'status', label: 'Estado', class:'align-middle', width:"100px"},          
            {key: 'error', label: 'Error', class:'align-middle', width:"50px"},             
          ],
          currentPage: 1,
          perPage: 5,          
        },       
        filter: {
          date_start: moment().format('YYYY-MM-DD'),      
          date_end: moment().format('YYYY-MM-DD'),     
          time_start: moment().subtract(10, 'minutes').format('HH:mm'),
          time_end: moment().format('HH:mm'),
          node: 0,  
          sensor: 0,
          module_id: 0,
          reference: 'sensors_customer_tank_id',
          reference_val_id: '',
          show: {
            hours: false
          }
        },
        maps: {
          urlMaps: '',
          url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
          options: {zoomControl: false, zoomSnap: true},          
          zoom: 12,
          center: [],
          markerLatLng: [],                  
        },        
        chart: {
          loaded: false,
          realTime: {
            interval: null,
            refresh: 5000,
          },
          chartdata: {},
        },              
        primaryColor: '',           
        batteryStatus: 0,
        cantidadStatus: 0,
        litersStatus: 0,
        litersCone: 0,
        litersStatusCone: 0,
        litersCylinder: 0,
        litersStatusCylinder: 0, 
        statusTrough: '',
        degreesTemperature: 0,
      }
    },    
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Config Customer */      
      this.customerID = Helper.getCustomerId()
      /* Fin Config Customer */      
    },  
    mounted () {      
      this.data.id = this.$route.params.customerTankID            
      this.setup()
      this.load()                 
    },
    destroyed () {      
      clearInterval(this.chart.realTime.interval)      
    },
    computed: {
      alertMin() {
        var status = 0
        if(this.cantidadStatus < this.data.info.alert_min) {
          status = 1;
        }
        if(this.cantidadStatus < this.data.info.alert_min_last) {
          status = 2;
        }        
        return status
      },
      showHours() {
        if(this.filter.date_start == this.filter.date_end) {
          return true
        } else {
          this.filter.show.hours = false
          return false
        }        
      },
      bateryAlert() {        
        if(this.data.info.batery_alert) {
          if(this.batteryStatus > this.data.info.batery_alert) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }      
      },   
      isCone() {
        if(this.data.info) {
          if(this.data.info.tank) {
            if(parseFloat(this.data.info.tank.cone_height)>0 &&
               parseFloat(this.data.info.tank.cone_radio_major)>0 &&
               parseFloat(this.data.info.tank.cone_radio_minor)>0 ) {
                 return true              
            } else {
              return false
            }
          } else {
            return false
          }
        } else {
          return false
        }
      },
      isCylinder() {
        if(this.data.info) {
          if(this.data.info.tank) {
            if(parseFloat(this.data.info.tank.cylinder_longitude)>0 &&
               parseFloat(this.data.info.tank.cylinder_radio)>0 ) {
                 return true              
            } else {
              return false
            }
          } else {
            return false
          }
        } else {
          return false
        }
      },                     
    },    
    methods: {
      validationCustomer(data) {
        if(this.customerID != data.m_customers_id) {
          this.$router.push({ name: 'Error403' })
        }
      },
      getRowCount (items) {
        return items.length
      },        
      load(){
        let loader = this.$loading.show();
        var result = serviceAPI.obtenerVinculacionTanque(this.data)

        result.then((response) => {
          var data = response.data      
          this.data.info = data     
          
          // VALIDACION DE VISUALIZACION
          this.validationCustomer(data)
          // FIN VALIDACION

          if(data.lat && data.lng) {          
            this.maps.urlMaps = 'https://www.openstreetmap.org/#map=19/' + data.lat + '/' + data.lng
            this.maps.center = [data.lat, data.lng]
            this.maps.markerLatLng = [data.lat, data.lng]                  
          }

          this.filter.node = data.sensor_code
          this.filter.sensor = data.sensor_val
          this.loadChart()          
          this.configChartRealTime()          
          this.configFilters()
          this.loadLastReport()

          loader.hide()
        })
        .catch(error => {     
          loader.hide()
          this.$router.push({ name: 'Error500' })          
          //this.$awn.alert(Error.showError(error))
        });
      },
      loadLastReport() {        
        var result = serviceAPI.obtenerLastReport(this.filter)

        result.then((response) => {
          var data = response.data 
          
          if(this.data.info.type_asignation == 'tanque') {
            this.batteryStatus = data.bateria / 10
            this.cantidadStatus = this.getCantidadStatus(data.val)
            this.litersStatus = this.getLiters(data.val)
            this.litersCone = this.getLitersCone(0)
            this.litersStatusCone = this.getLitersStatusCone(data.val)       
            this.litersCylinder = this.getLitersCylinder()
            this.litersStatusCylinder = this.getLitersStatusCylinder(data.val)             
            this.degreesTemperature = this.getDegreesTemperature(data.wire1)
          } 

          if(this.data.info.type_asignation == 'bebedero') {
            this.batteryStatus = data.bateria/10
            this.statusTrough = this.getStatusTrough(data.entrada)
            this.degreesTemperature = this.getDegreesTemperature(data.wire1)
          }          
        }) 
      },
      getCantidadStatus(value) {                        
        if(value) {       
          if(this.isCone) {
            let heightConeTank = parseFloat(this.data.info.tank.height) + parseFloat(this.data.info.tank.cone_height)
            return ((( heightConeTank - (value + parseFloat(this.data.info.distance_sensors))) / heightConeTank) * 100).toFixed(2)
          } else {             
            return (((this.data.info.tank.height - (value + parseFloat(this.data.info.distance_sensors))) / this.data.info.tank.height) * 100).toFixed(2)
          }
        } else {
          return 0
        }        
      },
      getLiters(value) {
        let liters = 0

        if(value) {          
          if(this.data.info.capacity>0) {
            liters = ((this.data.info.capacity * this.getCantidadStatus(value)) / 100)
          } else {
            liters = ((this.data.info.tank.capacity * this.getCantidadStatus(value)) / 100)
          }          
        } else {
          liters = 0
        }         
        
        if(this.isCone) {
          liters = liters + this.getLitersStatusCone(value)
        } 
        
        return liters.toFixed(2)
      },
      getLitersCone (radioMajor) {
        var result = 0
        if(this.isCone) {
          var cone_height = this.data.info.tank.cone_height           
          var cone_radio_minor = this.data.info.tank.cone_radio_minor
          var cone_radio_major = 0
          if(radioMajor) {
            cone_radio_major = radioMajor
          }            
          else {
            cone_radio_major = this.data.info.tank.cone_radio_major
          }   
          
          var calcRadioMajorCuadrado =  cone_radio_major * cone_radio_major
          var calcRadioMenorCuadrado =  cone_radio_minor * cone_radio_minor
          var calcMultiplicacionRadios = cone_radio_major * cone_radio_minor
          var calcSumaRadios = calcRadioMajorCuadrado + calcRadioMenorCuadrado + calcMultiplicacionRadios

          var calcPIxAltura = Math.PI * cone_height
          var calcFraccionUnoSobreTres = 1/3
          
          var calcFinal = ((calcFraccionUnoSobreTres * calcPIxAltura) * calcSumaRadios) / 1000

          result = calcFinal
        }
        return result
      },
      getLitersStatusCone (value) {
        var result = 0
        if(this.isCone) {
          var cone_height = this.data.info.tank.cone_height           
          var cone_radio_minor = this.data.info.tank.cone_radio_minor
          var cone_radio_major = this.data.info.tank.cone_radio_major

          var cone_radio_major_new = 0

          var newHeightCone = parseFloat(this.data.info.tank.height) - (value + parseFloat(this.data.info.distance_sensors))

          if(newHeightCone<0) {
            newHeightCone = Math.abs(newHeightCone)
            var new_radio_major = (newHeightCone * cone_radio_major) / cone_height
            result = this.getLitersCone(cone_radio_major) - this.getLitersCone(new_radio_major) 
          } else {            
            result = this.getLitersCone(cone_radio_major)
          }                   
        }
        return parseFloat(result)
      },
      getLitersCylinder(){
        var result = 0
        if(this.isCylinder) {
          var cylinder_longitude = parseFloat(this.data.info.tank.cylinder_longitude)
          var cylinder_radio = parseFloat(this.data.info.tank.cylinder_radio)

          var calcFinal = Math.PI * ((cylinder_radio * cylinder_radio) * cylinder_longitude)
          result = calcFinal / 1000      
        }
        return result
      },
      getLitersStatusCylinder(value) {
        var result = 0
        if(this.isCylinder) {
          var cylinder_longitude = parseFloat(this.data.info.tank.cylinder_longitude)
          var cylinder_radio = parseFloat(this.data.info.tank.cylinder_radio)
          var cylinder_height = (parseFloat(this.data.info.tank.cylinder_radio) * 2) - value          
          
          var calc1 = (Math.acos((cylinder_radio-cylinder_height)/cylinder_radio)) * (cylinder_radio*cylinder_radio)
          var calc2 = (cylinder_radio-cylinder_height) * cylinder_radio * Math.sin(Math.acos(((cylinder_radio - cylinder_height)/cylinder_radio)))
          var calc3 = ((calc1 - calc2) * cylinder_longitude) / 1000

          result = calc3
        }
        return parseFloat(result).toFixed(2) 
      },
      getStatusTrough(entrada) {
        if(entrada==1) {
          return 1
        }
        if(entrada==0) {
          return 0
        }        
        if(entrada==undefined) {
          return -1
        }
      },
      getDegreesTemperature(temp) {
        if(temp) {
          return parseFloat(temp).toFixed(2)
        } else {
          return parseFloat('0').toFixed(2)
        }
        
      },

      loadChart() {                        
        var result = serviceAPI.filtrarDatosSensores(this.filter)

        result.then((response) => {
          var data = response.data 
          
          if(data.length) {
            this.initChartData()
            data.forEach((item)=>{
              var horas = moment(item.fecha_hora).format('DD MMMM - HH:mm')
                        
              this.chart.chartdata.labels.push(horas)
              this.chart.chartdata.datasets[0].data.push(this.getCantidadStatus(item.val))

              this.chart.chartdata.datasets[1].data.push(this.data.info.alert_min)
              this.chart.chartdata.datasets[2].data.push(this.data.info.alert_min_last)
                
              this.chart.loaded = true
            })
          } else {
            this.chart.loaded = false            
          } 
          
          this.loadLastAlert()
        })  
        .catch(error => {          
          this.$awn.alert(ErrorToken.valid(error))          
        });                
      },
      loadLastAlert() {
        var result = serviceAPI.obtenerUltimasAlertas(this.filter)

        result.then((response) => {
          var data = response.data 
          this.table.items = data
        }) 
      },
      initChartData() {
        this.chart.chartdata = {
          labels: [],
          datasets: [{
            label: 'Estado',
            backgroundColor: this.primaryColor,
            pointRadius: 2,
            order:3,
            data: [],
          },
          {
            label: 'Valor Mínimo',
            borderColor: 'darkorange',
            backgroundColor: 'transparent',
            borderWidth: 1,
            pointRadius: 1,
            order:2,
            data: [],
          },
          {
            label: 'Último Valor Mínimo',
            borderColor: 'darkred',
            backgroundColor: 'transparent',
            borderWidth: 1,
            pointRadius: 1,
            order:1,
            data: [],
          }]
        }
      },
      configChartRealTime() {
        this.chart.realTime.interval = setInterval(() => {            
          this.loadChart()
          this.loadLastAlert()
          this.loadLastReport()
        }, this.chart.realTime.refresh);
      },
      configFilters() {
        this.filter.module_id = Modules.SENSORES
        this.filter.reference_val_id = this.data.id
      },
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"
        hoja.innerHTML = hoja.innerHTML  + ".b-pagination .page-item.active .page-link { background-color: " + this.primaryColor + "; border-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      },   
      irNotifications() {
        this.$router.push({ name: 'SensorsCustomerNotificationsSensorsTank', params: { customerTankID: this.data.id } })                  
      },

      inactiveNotifications() {
        let loader = this.$loading.show();

        var result = serviceAPI.inactivarNotificaciones(this.data.id);
        
        result.then((response) => {          
          loader.hide()
          this.load()
          this.$awn.success("Notificaciones Inactivas");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      activeNotifications() {
        let loader = this.$loading.show();

        var result = serviceAPI.activarNotificaciones(this.data.id);
        
        result.then((response) => {          
          loader.hide()
          this.load()
          this.$awn.success("Notificaciones Activas");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
      }        
    }
  }
</script>
<style>
  .sensor-detail-small-info-tank {
    cursor: pointer;
  }
  .sensor-customer-detail-custom-mapa {
    padding: 0px;
    height: 150px;
  }
  .detail-sensor-tank-img {
    width: 90%;
  }
  .sensors-detail-data-main {
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    /*margin-left: 25px;*/
    font-size: 17px;
  }  

  @media only screen and (max-width: 768px) {
    .sensors-detail-data-main {
      font-size: 15px;
    }
  }

  .sensors-detail-title-data {
    font-size: 18px;
  }  
  .sensors-detail-summary-data-title {    
    font-weight: bold;
  } 
  .sensors-detail-summary-data-value-darkred {    
    font-weight: bold;
    color: darkred;
  }  
  .sensors-detail-summary-data-value-darkorange {    
    font-weight: bold;
    color: darkorange;
  }      
  .sensors-detail-summary-data-value-darkgreen {    
    font-weight: bold;
    color: darkgreen;
  }      

  .sensor-detail-batery-top {
    font-weight: bold;
    color: darkgreen;
    text-align: center;
  } 
  .sensor-detail-batery-min {
    font-weight: bold;
    color: darkred;
    text-align: center;
  }
  .sensors-detail-data-main-batery-alert {
    font-size: 12px;
    font-weight: bold;
    color: darkred;
  }
  .sensors-detail-data-main-batery-alert-hr {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }    
  .detail-sensor-notifications-inactive {
    background-color: darkred;
    color: white;
    font-weight: bold;
    font-size: 17px;
    text-align: left;
    margin-bottom: 0px;
  }
  .detail-sensor-notifications-active {
    background-color: darkgreen;
    color: white;
    font-weight: bold;
    font-size: 17px;
    text-align: left;
    margin-bottom: 0px;
  }    
  .detail-sensor-button-inactive:hover {
    border-color: darkred;
    color: darkred;
  }
 .detail-sensor-button-inactive {
    border-color: darkred;
    background-color: darkred;
    color: white;
  }
  
  .detail-sensor-button-active:hover {
    border-color: darkgreen;
    color: darkgreen;    
  }
  .detail-sensor-button-active {
    border-color: darkgreen;
    background-color: darkgreen;
    color: white;
  }    

  .list-sensors-customers-trough-open {
    font-size: 18px;
    text-transform: uppercase;
    color: darkgreen;
    font-weight: bold;
  } 
  .list-sensors-customers-trough-close {
    font-size: 18px;
    text-transform: uppercase;
    color: darkred;
    font-weight: bold;
  }

  .sensors-detail-data-temperature-alert {
    font-size: 12px;
    font-weight: bold;
    color: darkblue;
  }
  .sensors-detail-data-temperature-alert-hr {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }    
  .sensors-detail-data-temperature-alert-value {
    font-size: 17px;
    font-weight: bold;
    color: darkblue;  
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #000;
    /* margin-left: 25px; */
    font-size: 17px;       
  }
</style>